interface Props {
  secondary?: boolean;
  text: string;
  icon: any;
  className?: string;
  onClickLink: string;
  id?: string;
}

export default function Button(props: Props) {
  const id = props.id ?? Math.random().toString();

  return (
    <>
      <a
        hidden
        target="_blank"
        rel="noreferrer"
        href={props.onClickLink}
        id={id}
      ></a>
      <button
        onClick={() => document.getElementById(id)?.click()}
        className={`
      ${props.secondary ? "secondary" : ""} 
      ${props.className ? props.className : ""}
      `}
      >
        <div className="d-flex align-items-center justify-content-center gap-2">
          <img src={props.icon} alt="phone icon" />
          <span>{props.text}</span>
        </div>
      </button>
    </>
  );
}
