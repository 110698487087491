import Button from "../../../components/Button";
import phone from "../../../assets/phone.svg";
import chat from "../../../assets/chat.svg";
import users from "../../../assets/users.svg";
import value from "../../../assets/value.svg";
import love from "../../../assets/love.svg";
import hero from "../../../assets/hero.webp";

export default function Hero() {
  return (
    <section id="hero" className="pt-5">
      <div style={{ backgroundImage: `url(${hero})` }} className="bg"></div>
      <div className="container py-5 px-3">
        <div className="content">
          <hgroup>
            <h6>Onoranze Funebri Parisi Mario</h6>
            <h1>Funerali e Cremazioni a Catania e Provincia</h1>
            {/* <h2>Parisi Mario SNC</h2> */}
          </hgroup>
          <br />
          <div className="row">
            <p className="col-md-6">
              <strong>Contattaci 24h</strong>, per richiedere un{" "}
              <strong>Preventivo</strong>.
            </p>
            {/* <p>Sei in buone mani!</p> */}
          </div>
          <div className="d-md-flex align-items-center w100 gap-5 px-3">
            <hr className="w100" />
            <div className="d-flex flex-column flex-md-row justify-content-around gap-2 gap-md-4">
              <Button
                id="cta-hero-1"
                onClickLink={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                text="Chiama Subito (24h)"
                icon={phone}
                className="tag-manager-call"
              />
              <Button
                id="cta-hero-2"
                secondary
                icon={chat}
                text="Invia un Messaggio"
                onClickLink={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_PHONE_NUMBER}&text=Salve, vorrei sapere di più sui vostri servizi.`}
              />
            </div>
          </div>
          <div className="keypoints d-md-flex justify-content-around px-5 mt-5">
            <div className="d-flex gap-2 my-1 justify-content-between px-0">
              <div>
                <img src={users} alt="icon funeral" />
              </div>
              <div>Oltre 40.000 famiglie ci hanno scelto</div>
            </div>
            <div className="d-flex gap-2 my-1 justify-content-between px-0">
              <div>
                <img src={value} alt="icon funeral" />
              </div>
              <div>Massima qualità del nostro servizio, dal 1954</div>
            </div>
            <div className="d-flex gap-2 my-1 justify-content-between px-0">
              <div>
                <img src={love} alt="icon funeral" />
              </div>
              <div>Valore umano del nostro team</div>
            </div>
            {/* <div className="d-flex gap-2 my-3 justify-content-around">
              <div>
                <img src={contract} alt="icon funeral" />
              </div>
              <div>An Easy Process</div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="oval"></div>
    </section>
  );
}
