import logo from "../../assets/logo.webp";
import phone from "../../assets/phone.svg";
import Button from "../Button";

export default function Nav() {
  const scrollToSection = (
    e: React.MouseEvent<HTMLAnchorElement>,
    id: string
  ) => {
    e.preventDefault(); // Prevent default anchor behavior
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const links = (
    <div className="d-flex justify-content-center align-items-center gap-4">
      <u>
        <a
          id="nav-funeral"
          className="d-flex"
          href="#funeral"
          onClick={(e) => scrollToSection(e, "funeral")}
        >
          Funerali
        </a>
      </u>
      <u>
        <a
          id="nav-cremation"
          className="d-flex gap-1"
          href="#cremation"
          onClick={(e) => scrollToSection(e, "cremation")}
        >
          Costi Cremazione
        </a>
      </u>
      <u>
        <a
          id="nav-contact"
          className="d-flex"
          href="#contact"
          onClick={(e) => scrollToSection(e, "contact")}
        >
          Contatti
        </a>
      </u>
    </div>
  );

  return (
    <nav className="pb-2 pb-lg-0">
      <div className="container">
        <div className="content">
          <div className="d-flex justify-content-between pt-3 pt-md-3 pb-3">
            <div className="logo mt-auto m-lg-0">
              <img src={logo} alt="logo parisi mario onoranze funebri" />
            </div>
            <div className="d-none d-lg-flex">{links}</div>
            <div className="d-flex flex-column align-items-center">
              {/* <small>
                <i>Pronto Intervento</i>
              </small> */}
              <Button
                id="cta-1"
                onClickLink={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                text={`348 419 16 93 (24h)`}
                icon={phone}
                className="tag-manager-call"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center d-lg-none mt-2">
            {links}
          </div>
        </div>
      </div>
    </nav>
  );
}
