import About from "./Parts/About";
import Hero from "./Parts/Hero";
import Pre from "./Parts/Pre";
import Reviews from "./Parts/Reviews";
import Services from "./Parts/Services";
import Strength from "./Parts/Strength";
import Where from "./Parts/Where";

import value from "../../assets/value.svg";
import hero from "../../assets/hero.webp";
// import cremation from "../../assets/cremation.webp";
import cremation from "../../assets/urna.webp";
import contract from "../../assets/contract.webp";

export default function Home() {
  return (
    <>
      <Hero />
      <br />
      <br />
      <About />
      <br />
      <br />
      <Reviews />
      <div className="d-none d-md-block">
        <br />
        <br />
        <Strength />
        <br />
        <br />
      </div>
      <Services />
      <br />
      <br />
      <Pre
        btnText="Chiama per un preventivo"
        id="funeral"
        heroTitle="Funerali"
        heroSubtitle="Organizzeremo il funerale con professionalità e rispetto. Non dovrai pensare a niente."
        steps={[
          { text: "Camera ardente", icon: value },
          { text: "Auto funebre", icon: value },
          { text: "Feretro in legno", icon: value },
          { text: "Frigorifero salma", icon: value },
          { text: "Composizione salma", icon: value },
          { text: "Disbrigo documentazione", icon: value },
          { text: "Fiori", icon: value },
          { text: "Ogni aspetto Cimiteriale", icon: value },
        ]}
        mainTitle="Interveniamo per Servizi Funebri in tutta la Sicilia"
        text1="La trasparenza sui costi e la chiarezza su ciò che è fornito rappresentano il primo motivo di attenzione del cliente verso la nostra impresa di onoranze funebri a Catania e provincia."
        text2="La nostra impresa fornirà continuo supporto 24h ai familiari esonerandoli da qualsiasi incombenza organizzativa e burocratica."
        image={hero}
      />
      <br />
      <br />
      <Pre
        btnText="Chiama per un preventivo"
        dark
        id="cremation"
        heroTitle="Costi Cremazione"
        heroSubtitle={
          <>
            La cremazione oltre ad essere una soluzione ecologica, permette di{" "}
            <strong>risparmiare almeno 3.000 € di costi cimiteriali</strong>.{" "}
            <br />
            <br />
            Sono infatti molti gli oneri da affrontare quando si svolge un
            normale funerale, a partire dalla stipula della concessione di un
            loculo, che si aggira attorno ai 2.200 €, alla quale si aggiungono
            spese di registrazione, tumulazione ed altri accessori che possono
            essere previsti. <br />
            <br />
            <strong>
              Per richiedere un Preventivo Personalizzato contattaci.
            </strong>
          </>
        }
        steps={[
          { text: "Camera ardente", icon: value },
          { text: "Documenti Cremazione", icon: value },
          { text: "Autofunebre", icon: value },
          { text: "Feretro in legno eco-vernice", icon: value },
          { text: "Documenti Destinazione ceneri", icon: value },
          { text: "Gestione della cremazione", icon: value },
          { text: "Urna cineraria", icon: value },
        ]}
        mainTitle="Cremazioni in tutta la Sicilia"
        text1="Sin dal primo contatto, forniremo in forma scritta e dettagliata un preventivo che rappresenterà per noi un impegno anche a distanza di tempo."
        text2={
          <>
            <strong>Volontà alla Cremazione</strong>
            <p>
              Ci occuperemo delle pratiche inerenti alla normativa per la
              cremazione in cui, in base al caso, individueremo chi o coloro che
              debbano esprimere la volontà alla cremazione. Troveremo soluzione
              anche nel caso in cui chi debba firmare si trovi a distanza.
            </p>
          </>
        }
        text3={
          <>
            <strong>Destinazione delle Ceneri</strong>
            <p>
              Spiegheremo ai familiari le alternative di destinazione delle
              ceneri e, una volta deciso, ci occuperemo delle pratiche
              necessarie, fino all’organizzazione e all’assistenza alla
              dispersione in natura (mare o campagna) se questa è la volontà.
              Nei comuni in cui la dispersione in natura non è contemplata, ci
              occuperemo di evidenziare le normative a superamento di tale
              limite.
            </p>
          </>
        }
        image={cremation}
      />
      <br />
      <br />
      <Pre
        btnText="Chiama per maggiori informazioni"
        id="contract"
        heroTitle="Contratto in Vita"
        heroSubtitle="Una scelta personale di sensibilità verso i propri cari, allevia le loro responsabilità in un momento difficile"
        steps={[
          { text: "Camera ardente", icon: value },
          { text: "Auto funebre", icon: value },
          { text: "Feretro in legno", icon: value },
          { text: "Frigorifero salma", icon: value },
          { text: "Disbrigo documentazione", icon: value },
          { text: "Ogni dettaglio aggiuntivo", icon: value },
        ]}
        mainTitle="Contratto in vita con Assicurazioni Generali"
        text1="Il contratto in vita, è una scelta di sensibilità verso i propri familiari ai quali non si vuole lasciare nessuna pendenza finanziaria e organizzativa. Parisi Mario Servizi Funebri è la prima impresa in Sicilia ad offrire la possibilità di stipulare un contratto in vita per un funerale."
        text2={
          <>
            <strong>
              Una sensibilità verso i propri cari a non lasciare nessun onere
              finanziario e organizzativo, ti permette di:
            </strong>
            <p>
              <ul>
                <li>
                  Esprimere liberamente la propria volontà alla cremazione
                </li>
                <li>
                  Assicurarsi di includere nel contratto la documentazione
                  necessaria a tale volontà
                </li>
                <li>
                  Essere sicuri che le proprie volontà, ad esempio la
                  dispersione in natura o scelte sulla celebrazione, vengano
                  rispettate
                </li>
                <li>
                  Cominciare a pagare anche a rate, diluendo l’importo nel tempo
                </li>
              </ul>
            </p>
          </>
        }
        text3="Inoltre, grazie alla nostra collaborazione con Assicurazioni Generali, il contratto offre numerosi vantaggi, tra questi in caso di premorienza senza aver completato il pagamento delle rate, sarà l’assicurazione stessa ad intervenire a completamento dell’importo totale"
        image={contract}
      />
      <br />
      <br />
      <Where />
    </>
  );
}
