import about from "../../../assets/about.webp";
import phone from "../../../assets/phone.svg";
import Button from "../../../components/Button";

export default function About() {
  return (
    <div className="container">
      <div className="content">
        <div className="row px-3">
          <div className="col-md">
            <div className="pt-5 text-center">
              <span className="h2">
                <strong>
                  Impresa Parisi Mario... <br /> I nostri Valori
                </strong>
              </span>
              <br />
              <br />
              <p>
                <strong>Dal 1954</strong> l’impresa Parisi ha fondato lo
                sviluppo aziendale sui <strong>valori familiari</strong>,
                servendo oltre <strong>40.000</strong> famiglie in tutta la
                provincia di <strong>Catania per funerali e cremazioni.</strong>
              </p>
              <p>
                Il nostro intento primario è di{" "}
                <strong>
                  proteggere l’intimo momento di raccoglimento dei familiari
                </strong>
                , grazie al nostro personale che ci rappresenta degnamente nei
                domicili in cui abbiamo accesso.
              </p>
              <br />
              <Button
                id="cta-about"
                onClickLink={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                text="Chiamaci per Informazioni"
                icon={phone}
                className="tag-manager-call"
              />
            </div>
          </div>
          <div className="col-md">
            <div className="h100 d-flex align-items-center justify-content-center p-5">
              <div className="img-canvas">
                <img
                  className="w100"
                  src={about}
                  alt="onoranze funebri mario parisi"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
